<template>
	<el-dialog :title="'周报审核'" :close-on-click-modal="false" :visible.sync="visible"
			   width="600px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-form-item label="审核人" prop="examineMan">
				<el-input class="selItemInput" v-model="dataForm.examineMan" placeholder="审核人" style="width:300px"></el-input>
			</el-form-item>
			<el-form-item label="审核时间" prop="examineTime">
				<el-date-picker class="selItemInput"
								value-format="yyyy-MM-dd"
								v-model="dataForm.examineTime"
								type="date" style="width:300px">
				</el-date-picker>
			</el-form-item>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick @click="visible = false" class="primaryPlainBtn" type="primary" plain>取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	export default {
		name: "weekly-audit",
		data() {
			return {
				visible: false,
				inline: false,
				dataForm: {
					//标的物ID
					recNo: '',
					//审核时间
					examineTime: '',
					//审核人
					examineMan: ''
				},
				dataRule: {
					examineMan: [
						{ required: true, message: "审核人不能为空", trigger: "blur" }
					],
					examineTime: [
						{ required: true, message: "审核时间不能为空", trigger: "blur" }
					]
				},
			}
		},
		methods: {
			init(id) {
				this.dataForm = {
					//标的物ID
					recNo: '',
					//审核时间
					examineTime: '',
					//审核人
					examineMan: ''
				},
				this.$nextTick(() => {
					this.dataForm.recNo = id;
					this.visible = true;
				});
				this.$refs['dataForm'].resetFields();
			},
			// 表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						this.$http({
							url:  this.$store.state.httpUrl + "/business/weeklyreportinfo/checkWeekly",
							method: "post",
							data: this.$http.adornData(this.dataForm)
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});

			},

		}
	}
</script>
<style scoped>

</style>
